.blog .blog-articles {
  padding-top: 3rem;
}
.blog .blog-articles .hvr {
  display: none;
  width: 4rem;
  height: 2rem;
}
.blog .blog-articles .not-hvr {
  display: block;
  width: 4rem;
  height: 2rem;
}
.blog .blog-articles .card-deck {
  margin-left: 0;
  margin-right: 0;
}
.blog .blog-articles .card {
  margin: unset;
  border: none;
}
.blog .blog-articles .article {
  position: relative;
  z-index: 5;
  padding-bottom: 2.2rem;
}
.blog .blog-articles .article .more {
  flex-grow: 0;
}
.blog .card img {
  // Two following lines make each card images' height the same
  height: 50%;
  object-fit: cover;
}
.blog .blog-articles .article .card-body {
  border: solid 2px #c8c8c8;
  border-top: none;
  padding-left: 2rem;
  padding-right: 2rem;
}
.blog .blog-articles .article .card-title {
  font-family: 'Montserrat-Regular', 'Helvetica Neue', 'Arial', sans-serif;
  font-size: 2rem;
  letter-spacing: 0.13rem;
  text-align: left;
  color: #4a4a4a;
}
.blog .blog-articles .article .card-text {
  font-family: 'Montserrat-Light', 'Helvetica Neue', 'Arial', sans-serif;
  font-size: 1.4rem;
  letter-spacing: 0.23rem;
  text-align: left;
  color: #4a4a4a;
  padding-bottom: 2.2rem;
}
.blog .blog-articles .article .btn-block {
  font-family: 'Montserrat-SemiBold', 'Helvetica Neue', 'Arial', sans-serif;
  font-size: 1.2rem;
  letter-spacing: 0.69rem;
  text-transform: uppercase;
  text-align: left;
  color: #4a4a4a;
}
.blog .blog-articles .quote {
  overflow: hidden;
  background-color: #ffcb00;
  margin-bottom: 3rem;
  margin-left: -1.5rem;
  min-width: 100vw;
  padding-left: 5rem;
  padding-right: 5rem;
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.blog .blog-articles .quote .parallax-container {
  height: 100%;
  position: relative;
}
.blog .blog-articles .quote .parallax-images {
  position: absolute;
  width: 100%;
}
.blog .blog-articles .quote .parallax-images {
  background: url('/assets/img/svg/icons/img-illustrations.svg') repeat;
  background-size: cover;
  height: 100%;
}
.blog blockquote {
  padding: 0 1rem;
  border-left: 0.25em solid #dfe2e5;
  margin: 0 0 2rem;
  font-style: italic;
}
@media screen and (min-width: 768px) {
  .blog .blog-articles .quote .parallax-images {
    background-size: 100%;
    height: 200%;
  }
}
.blog .blog-articles .quote .parallax-images {
  position: absolute;
  top: 70vw;
  z-index: 1;
}
.blog .blog-articles .quote .card-text {
  font-family: 'DINPro-Medium', 'Helvetica Neue', 'Arial', sans-serif;
  font-size: 2rem;
  letter-spacing: 0.27rem;
  text-align: center;
  color: white;
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.blog .blog-articles .quote .bottom {
  width: 4.9rem;
}
.blog .blog-articles .quote .top {
  width: 4.9rem;
  transform: rotate(-180deg);
}
.blog .blog-articles .details .title {
  font-family: 'Montserrat-Regular', 'Helvetica Neue', 'Arial', sans-serif;
  font-size: 2.4rem;
  letter-spacing: 0.16rem;
  text-align: left;
}
.blog .blog-articles .details .date {
  font-family: 'Montserrat-Light', 'Helvetica Neue', 'Arial', sans-serif;
  font-size: 1.4rem;
  letter-spacing: 0.23rem;
  text-align: left;
  color: #4a4a4a;
}
.blog .blog-articles .details .contents p {
  font-family: 'DINPro-Regular', 'Helvetica Neue', 'Arial', sans-serif;
  font-size: 1.8rem;
  color: #212121;
  line-height: 30px;

  strong {
    // This property fixes bold not applied automatically when setting font-weight: bolder/600 because of the font, weird
    font-family: 'DINPro-Medium', 'Helvetica Neue', 'Arial', sans-serif;
  }
}
.blog .blog-articles .details .contents img {
  height: auto;
  max-width: 70%;
}
.blog .blog-articles .details .facebook {
  background-color: #46639c;
  margin-bottom: 1rem;
}
.blog .blog-articles .details .twitter {
  background-color: #1893d1;
  margin-bottom: 1rem;
}
.blog .blog-articles .details .buttons {
  margin-bottom: 2rem;
}
@media screen and (min-width: 768px) {
  .blog .blog-articles {
    padding-top: 4rem;
    padding-left: 6rem;
    padding-right: 6rem;
  }
  .blog .blog-articles .card-deck {
    margin-right: -2.5rem;
    margin-left: 0;
    margin-bottom: 1.8rem;
  }
  .blog .blog-articles .card-deck .card {
    margin-right: 2.5rem;
  }
  .blog .blog-articles .article .card-title {
    font-size: 2.2rem;
    letter-spacing: 0.15rem;
    padding-top: 1rem;
    padding-bottom: 1.2rem;
  }
  .blog .blog-articles .article .card-text {
    font-size: 1.2rem;
    letter-spacing: 0.2rem;
    padding-bottom: 4rem;
  }
  .blog .blog-articles .article .btn-block {
    font-size: 1.2rem;
    letter-spacing: 0.69rem;
  }
  .blog .blog-articles .quote {
    border: none;
    margin-top: 2rem;
    margin-left: -6rem;
    padding-top: 12rem;
    padding-bottom: 12rem;
    padding-left: 6rem;
    padding-right: 6rem;
  }
  .blog .blog-articles .quote .parallax-images {
    top: 0;
  }
  .blog .blog-articles .quote .card-text {
    font-size: 2.8rem;
    letter-spacing: 0.37rem;
    margin-left: 2rem;
    margin-right: 2rem;
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
  .blog .blog-articles .details .title {
    font-size: 3rem;
    letter-spacing: 0.2rem;
    margin-bottom: 0.2rem;
    margin-top: 0;
  }
  .blog .blog-articles .details .date {
    font-size: 1.4rem;
    letter-spacing: 0.23rem;
  }
  .blog .blog-articles .details .contents p {
    font-size: 1.8rem;
  }
  .blog .blog-articles .details .facebook {
    margin-right: 1.1rem;
  }
  .blog .blog-articles .details .twitter {
    margin-left: 1.1rem;
  }
}
@media screen and (min-width: 992px) {
  .blog .blog-articles .head {
    margin-top: -4rem;
    margin-left: -6rem;
    margin-right: -6rem;
    margin-bottom: 6rem;
  }
  .blog .blog-articles .head .card-title {
    font-family: 'Montserrat-Bold', 'Helvetica Neue', 'Arial', sans-serif;
    width: 110rem;
    font-size: 5rem;
    text-align: center;
    color: #4a4a4a;
  }
  .blog .blog-articles .head .card-text {
    font-family: 'DINPro-Regular', 'Helvetica Neue', 'Arial', sans-serif;
    font-size: 1.6rem;
    text-align: center;
    color: #4a4a4a;
  }
  .blog .blog-articles .article:hover .card-body {
    border: solid 2px #ffc107;
    border-top: none;
  }
  .blog .blog-articles .article:hover .card-body .btn-block {
    color: #ffc107;
  }
  .blog .blog-articles .article:hover .hvr {
    display: block;
  }
  .blog .blog-articles .article:hover .not-hvr {
    display: none;
  }
  .blog .blog-articles .article .card-title {
    font-size: 3rem;
  }
  .blog .blog-articles .article .card-text {
    font-size: 1.6rem;
    letter-spacing: 0.27rem;
  }
  .blog .blog-articles .quote {
    border: none;
  }
  .blog .blog-articles .quote .card-text {
    font-size: 3rem;
    letter-spacing: 0.4rem;
  }
  .blog .blog-articles .quote .top {
    width: 8.4rem;
  }
  .blog .blog-articles .quote .bottom {
    width: 8.4rem;
  }
  .blog .blog-articles .details {
    float: left;
    margin-bottom: 2rem;
    margin-left: 1.3rem;
    margin-right: 1.3rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
    margin-left: 1.3rem;
    margin-right: 1.3rem;
  }
  .blog .blog-articles .details .title {
    letter-spacing: 0.17rem;
  }
  .blog .blog-articles .details .contents p {
    font-size: 1.6rem;
  }
  .blog .blog-articles .details .article .card-title {
    letter-spacing: 0.2rem;
  }
  .blog .blog-articles .details .article .card-text {
    font-size: 1.2rem;
    letter-spacing: 0.2rem;
  }
  .blog .blog-articles .details .article .btn-block {
    font-size: 1.4rem;
    letter-spacing: 0.8rem;
  }
}
